import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { ButtonComponent } from '../../components/Button'
import { LayoutPage } from '../../components/LayoutPage'
import { ListComponent } from '../../components/ListComponent'
import { PageTitleComponent } from '../../components/PageTitleComponent'
import { SpinnerComponent } from '../../components/Spinner'
import { TabsComponent } from '../../components/Tabs'

import { useAppContext } from '../../hooks/useAppContext'
import { useControlSearch } from '../../hooks/useControlSearch'
import { useSimpleState } from '../../hooks/useSimpleState'
import { useView } from '../../hooks/useView'

import './index.css'

const renderViewDealsMap = {
    0: 'new',
    1: 'published',
    2: 'reportedDead',
    3: 'dead',
    4: 'unpublished',
    5: 'deleted'
}

const renderViewDealsParamsMap = {
    0: 'publishStartDate[eq]=null&publishEndDate[eq]=null&deleteDate[eq]=null',
    1: 'publishStartDate[ne]=null&publishEndDate[eq]=null&deleteDate[eq]=null',
    2: 'clicksDead[gt]=0&deadDate[eq]=null&deleteDate[eq]=null',
    3: 'deadDate[ne]=null&deleteDate[eq]=null',
    4: 'publishEndDate[ne]=null&publishStartDate[eq]=null&deleteDate[eq]=null',
    5: 'deleteDate[ne]=null',
}

const ManageDealsPage = () => {
    const {ac} = useAppContext()

    const navigate = useNavigate()

    const { _state } = useSimpleState({
        tab: 0
    })

    const {_view: _viewMain} = useView({
        initialView: 'main',
        views: {
            loading: renderLoading,
            main: renderMain
        }
    })

    const {_view: _viewDeals} = useView({
        initialView: 'loading',
        views: {
            loading: renderDealsLoading,
            new: renderDealsNew,
            published: renderDealsPublished,
            reportedDead: renderDealsReportedDead,
            unpublished: renderDealsUnpublished,
            dead: renderDealsDead,
            deleted: renderDealsDeleted
        }
    })

    const {_control, _results, _params} = useControlSearch({
        params: renderViewDealsParamsMap[_state.get('tab')],
        beforeFetch: () => {
            _viewDeals.set('loading')
        },
        afterFetch: () => {
            _viewDeals.set(renderViewDealsMap[_state.get('tab')])
        }
    })

    const handleTabChange = (val) => {
        _state.set('tab', val)
        _params.set(renderViewDealsParamsMap[val])
    }

    const handleGoToEdit = (val) => {
        navigate(`/edit-deal/${val.id}`)
    }

    function renderLoading() {
        return (
            <div>
                <SpinnerComponent />
            </div>
        )
    }

    function renderMain() {
        return (
            <div>
                <LayoutPage config={{ id: 'ManageDeals' }}>
                    <PageTitleComponent value="Manage Deals" />

                    <TabsComponent value={_state.get('tab')} options={[
                        {
                            title: 'New',
                        },
                        {
                            title: 'Published',
                        },
                        {
                            title: 'Reported Dead',
                        },
                        {
                            title: 'Dead',
                        },
                        {
                            title: 'Unpublished',
                        },
                        {
                            title: 'Deleted'
                        }
                    ]} config={{ 
                        type: 'compact'
                    }} events={{
                        handleChange: handleTabChange
                    }} />

                    {_viewDeals.get()}
                </LayoutPage>
            </div>
        )
    }

    function renderDealsLoading() {
        return (
            <div className="manageDeals-deals">
                <SpinnerComponent />
            </div>
        )
    }

    function renderDealsNew() {
        let results = _results.get()

        return (
            <div className="manageDeals-deals">
                {results?.body?.data?.length ? (
                    <ListComponent value={results.body.data} config={{
                        controls: (item) => (
                            <>
                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => _control.fetchPublish(item, true)
                                }}>
                                    Publish
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => handleGoToEdit(item)
                                }}>
                                    Edit
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'gray'
                                }} events={{
                                    handleClick: () => _control.fetchDelete(item, true)
                                }}>
                                    Delete
                                </ButtonComponent>
                            </>
                        )
                    }} />
                ) : null}
            </div>
        )
    }

    function renderDealsPublished() {
        let results = _results.get()

        return (
            <div className="manageDeals-deals">
                {results?.body?.data?.length ? (
                    <ListComponent value={results.body.data} config={{
                        controls: (item) => (
                            <>
                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => _control.fetchPublish(item, false)
                                }}>
                                    Unpublish
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => _control.fetchDead(item, true)
                                }}>
                                    Mark Dead
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => handleGoToEdit(item)
                                }}>
                                    Edit
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'gray'
                                }} events={{
                                    handleClick: () => _control.fetchDelete(item, true)
                                }}>
                                    Delete
                                </ButtonComponent>
                            </>
                        )
                    }} />
                ) : null}
            </div>
        )
    }

    function renderDealsReportedDead() {
        let results = _results.get()

        return (
            <div className="manageDeals-deals">
                {results?.body?.data?.length ? (
                    <ListComponent value={results.body.data} config={{
                        controls: (item) => (
                            <>
                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => _control.fetchDead(item, true)
                                }}>
                                    Mark Dead
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => handleGoToEdit(item)
                                }}>
                                    Edit
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'gray'
                                }} events={{
                                    handleClick: () => _control.fetchDelete(item, true)
                                }}>
                                    Delete
                                </ButtonComponent>
                            </>
                        )
                    }} />
                ) : null}
            </div>
        )
    }

    function renderDealsUnpublished() {
        let results = _results.get()

        return (
            <div className="manageDeals-deals">
                {results?.body?.data?.length ? (
                    <ListComponent value={results.body.data} config={{
                        controls: (item) => (
                            <>
                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => _control.fetchPublish(item, true)
                                }}>
                                    Publish
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => handleGoToEdit(item)
                                }}>
                                    Edit
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'gray'
                                }} events={{
                                    handleClick: () => _control.fetchDelete(item, true)
                                }}>
                                    Delete
                                </ButtonComponent>
                            </>
                        )
                    }} />
                ) : null}
            </div>
        )
    }

    function renderDealsDead() {
        let results = _results.get()

        return (
            <div className="manageDeals-deals">
                {results?.body?.data?.length ? (
                    <ListComponent value={results.body.data} config={{
                        controls: (item) => (
                            <>
                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => _control.fetchDead(item, false)
                                }}>
                                    Unmark Dead
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => handleGoToEdit(item)
                                }}>
                                    Edit
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'gray'
                                }} events={{
                                    handleClick: () => _control.fetchDelete(item, true)
                                }}>
                                    Delete
                                </ButtonComponent>
                            </>
                        )
                    }} />
                ) : null}
            </div>
        )
    }

    function renderDealsDeleted() {
        let results = _results.get()

        return (
            <div className="manageDeals-deals">
                {results?.body?.data?.length ? (
                    <ListComponent value={results.body.data} config={{
                        controls: (item) => (
                            <>
                                <ButtonComponent config={{
                                    palette: 'blue'
                                }} events={{
                                    handleClick: () => handleGoToEdit(item)
                                }}>
                                    Edit
                                </ButtonComponent>

                                <ButtonComponent config={{
                                    palette: 'gray'
                                }} events={{
                                    handleClick: () => _control.fetchDelete(item, false)
                                }}>
                                    Undelete
                                </ButtonComponent>
                            </>
                        )
                    }} />
                ) : null}
            </div>
        )
    }

    return _viewMain.get()
}

export { ManageDealsPage }