import PropTypes from 'prop-types'

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import 'dayjs/locale/en'

import dayjs from 'dayjs'

import './index.css'

const PickerDateComponent = ({ value, config={}, events={} }) => {
    const handleChange = (val) => {
        if (events.handleChange)
            events.handleChange(val.format('YYYY-MM-DD HH:mm:ss'))
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <DatePicker
                value={dayjs(value)}
                onChange={handleChange} />
        </LocalizationProvider>
    )
}

PickerDateComponent.propTypes = {
    value: PropTypes.string,

    config: PropTypes.shape({}),

    events: PropTypes.shape({
        handleChange: PropTypes.func
    })
}

export { PickerDateComponent }